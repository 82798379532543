.date {
  font-family: "Times New Roman", Times, serif;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.content {
  font-family: "Times New Roman", Times, serif;
  font-size: 1.5rem;
  line-height: 1.5;
}

.blog-title {
  font-family: "SourceSerifProBold";
  font-size: 24px;
  color: #333333;
  margin-bottom: 0;
}

.blog-date {
  font-family: "SourceSerifProRegular";
  font-weight: normal;
  font-size: 18px;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 0;
  margin-top: 12px;
}

.blog-body {
  font-family: "InterRegular";
  font-size: 17px;
  line-height: 1.7;
  color: #333333;
}

.blog-container {
  padding: 20px;
}

.blog-body.empty-paragraph {
  height: 0.02px; /* or whatever height you want for the gap */
}

.cook-image {
  position: relative;
  width: 250%;
  height: auto;
  margin-top: 50px;
}

.textContainer {
  max-width: 20%;
}

img {
  cursor: zoom-in;
}