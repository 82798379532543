/* Responsive styles */

/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  /* Adjust the left-hand box styles */
  .left-box {
    width: 100%;
    padding: 1rem;
    border-right: none;
    border-bottom: 1px solid #f0f0f0;
  }

  /* Adjust the selected blog post container styles */
  .selected-blog-post-container {
    width: 100%;
    padding: 1rem;
  }
}
