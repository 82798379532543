header {
  font-family: "SourceSerifProRegular", serif;
  font-style: normal;
  font-size: 36px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.title-link {
  text-decoration: none;
  color: #333333;
}

.pikanto {
  color: #2454ff;
}

.header--subtitle {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 1.8; */
  color: #000000;
}

hr {
  border: 0.0001px solid #e4e4e4;
  margin-bottom: 16px;
}

a {
  text-decoration: underline;
  color: #2454ff;
}

a:hover {
  text-decoration: underline;
  color: #1c3cae;
}

a.title-link:hover {
  text-decoration: none;
  color: inherit;
}

.ducks-frame {
  width: 205px; /* Adjust the width as per your requirement */
  overflow: hidden;
}

.ducks {
  display: flex;
  width: 200%; /* Increase the width to 200% */
  animation: walk 30s linear infinite;
}

.duck {
  width: 25px;
  height: auto;
  flex-shrink: 0;
  margin-right: 10px;
}

@keyframes walk {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0); /* Change the translateX value to negative */
  }
}

.header-container {
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
}

h1 {
  padding-right: 15px;
}

@media screen and (max-width: 480px) {
  h1 {
    padding-right: 18px;
  }

  .ducks-frame {
    width: 143px; /* Adjust the width as per your requirement */
  }

  .ducks {
    animation: walk 20s linear infinite;
  }

  header {
    font-size: 32px;
  }
}

.header-title-frame {
  width: auto;
  white-space: nowrap;
  padding-right: 20px;
  margin-bottom: 24px;
}
