@font-face {
  font-family: "SuisseRegular"; /* you can name it whatever you want */
  src: url("./fonts/SuisseWorks-Regular.ttf") format("truetype"); /* adjust the URL/path accordingly */
}

@font-face {
  font-family: "SuisseBold"; /* you can name it whatever you want */
  src: url("./fonts/SuisseWorks-Bold.ttf") format("truetype"); /* adjust the URL/path accordingly */
}

@font-face {
  font-family: "SourceSerifProRegular"; /* you can name it whatever you want */
  src: url("./fonts/SourceSerifPro-Regular.otf") format("opentype"); /* adjust the URL/path accordingly */
}

@font-face {
  font-family: "SourceSerifProBold"; /* you can name it whatever you want */
  src: url("./fonts/SourceSerifPro-Semibold.otf") format("opentype"); /* adjust the URL/path accordingly */
}

@font-face {
  font-family: "InterRegular"; /* you can name it whatever you want */
  src: url("./fonts/Inter-Regular.ttf") format("truetype"); /* adjust the URL/path accordingly */
}

@font-face {
  font-family: "InterMedium"; /* you can name it whatever you want */
  src: url("./fonts/Inter-Medium.ttf") format("truetype"); /* adjust the URL/path accordingly */
}

@font-face {
  font-family: "InterBold"; /* you can name it whatever you want */
  src: url("./fonts/Inter-SemiBold.ttf") format("truetype"); /* adjust the URL/path accordingly */
}
