ul {
  list-style-type: none;
  color: #333333;
  font-family: "InterRegular";
  font-size: 24px;
  padding: 0;
  margin: 0;
  line-height: 1.8;
}

.post-content {
  padding-left: 10px;
  padding-right: 10px;
}

.post:hover,
.clicked-post .post {
  background-color: #2454ff;
  color: #f9f9f9;
}

.post {
  box-sizing: border-box;
  background-color: #f5f5f3;
  width: fit-content; /* this makes the element wrap around its content */
}

.post * {
  background-color: transparent;
}

.category-title {
  font-family: "InterRegular";
  color: #808080;
  font-size: 24px;
  padding-top: 24px;
  line-height: 1.8;
}

li:not(.post) {
  padding-left: 1.5em; /* Adjust this value to set the distance from the left edge to the text */
  text-indent: -1.4em; /* This negative value pulls the text back to the left by the amount set in padding-left */
}

@media screen and (max-width: 480px) {
  li:not(.post) {
    text-indent: -1em; /* This negative value pulls the text back to the left by the amount set in padding-left */
  }
  ul {
    font-size: 20px;
    max-width: 58%;
  }
  .category-title {
    font-size: 14px;
  }
}
